import React, { useState } from 'react';
import Logo from '../../images/LOGO_WHITE.png';
import './Contact.scss';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

const Contact = (props) => {
  let { data } = props;
  const [emri, setEmri] = useState('');
  const [email, setEmail] = useState('');
  const [teksti, setTeksti] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [result, setResult] = useState('');
  const [resultV, setResultV] = useState('');

  const [recaptcha, setRecaptcha] = useState(false);

  let errors = [];

  function ValidateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  function handleRecaptcha(value) {
    console.log('Captcha value:', value);
    setRecaptcha(true);
  }

  const formValidation = () => {
    setErrorMessages([]);

    const isNameValid = emri !== '';
    const isMessageValid = teksti !== '';

    if (emri === '') {
      errors.push('Emri nuk është i vlefshëm, ju lutem provoni përsëri.');
      // setEmri('emri');
    }
    if (email === '') {
      errors.push('Fusha e email është e zbrazët, ju lutem provoni përsëri.');
      // setEmail('email');
    } else if (!ValidateEmail(email)) {
      errors.push('Email nuk është i vlefshëm, ju lutem provoni përsëri.');
      // setEmailV('email');
    }
    if (!isMessageValid) {
      errors.push('Teksti nuk është i vlefshëm, ju lutem provoni përsëri.');
      // setTeksti('teksti');
    }
    if (errors.length > 0) {
      setShowErrors({ showErrors: true });
      setErrorMessages(errors);
    } else {
      setShowErrors({ showErrors: false });
      // alert('Email u dergua me sukses.');

      let objectToSend = {
        emri: emri,
        email: email,
        teksti: teksti,
      };
      console.log(objectToSend);

      axios
        .post(
          `${process.env.REACT_APP_BASEURL}/api/v1/general/webmail/new`,
          objectToSend
        )

        .then((res) => {
          // setResult('Sukses');
          setEmri('');
          setEmail('');
          setTeksti('');
          toast.success('Emaili u dërgua me sukses!');
          console.log('me sukses');
        })
        .catch((err) => {
          console.log('deshtoi');
          // setResultV('deshtoi');
          toast.error('Diqka shkoi gabim! Emaili nuk u dërgua!');
        });
    }
  };

  return (
    <>
      <div className="drop_form" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="comment_form">
                <h1>{data?.title}</h1>
                <form action="#">
                  {result === 'Sukses' ? (
                    <div className=" text-success contact_result">
                      <p> Mesazhi u dergua me sukses. </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {resultV === 'deshtoi' ? (
                    <div className=" text-danger contact_result">
                      <p> Diçka shkoi gabim, provoni përsëri! </p>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="form-group ">
                    <div className="input-container">
                      <i className="fa fa-user icon"></i>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="emri"
                        onChange={(e) => setEmri(e.target.value)}
                        required
                      />
                    </div>
                    {/* {emri === 'emri' ? (
                      <div className="text-danger">
                        Emri nuk është i vlefshëm, ju lutem provoni përsëri.
                      </div>
                    ) : (
                      ''
                    )} */}
                    <div className="input-container">
                      <i className="fa fa-envelope icon"></i>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    {/* {email === 'email' ? (
                      <div className="text-danger">
                        Fusha e email është e zbrazët, ju lutem provoni përsëri.
                      </div>
                    ) : (
                      ''
                    )} */}
                    {/* {emailV === 'email' ? (
                      <div className="text-danger">
                        Email nuk është i vlefshëm, ju lutem provoni përsëri.
                      </div>
                    ) : (
                      ''
                    )} */}
                    <div className="textarea">
                      <textarea
                        id="w3review"
                        name="w3review"
                        rows={4}
                        cols={100}
                        onChange={(e) => setTeksti(e.target.value)}
                        placeholder="     Teksti"
                      ></textarea>
                    </div>
                    {/* {emailV === 'email' && (
                      <div className="text-danger">
                        Teksti nuk është i vlefshëm, ju lutem provoni përsëri.
                      </div>
                    )} */}
                    {showErrors
                      ? errorMessages.map((item, index) => {
                          return (
                            <ul key={index} className="error_msg">
                              {item}
                            </ul>
                          );
                        })
                      : null}

                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA}
                      onChange={handleRecaptcha}
                    />
                    <div className="btn_send">
                      <button
                        type="button"
                        onClick={formValidation}
                        disabled={recaptcha == false ? true : false}
                      >
                        Dërgo
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 info__right">
              <div className="info_right">
                <img src={Logo} alt="logo" />
                <p>
                  {/* info@fondacionijeshil.org  */}
                  {/* {data?.contact_info?.[0]} */}
                </p>
                {/* <p>+383 44 000 000</p>
                <p>
                  Bregu i Diellit, Pristina <br /> Kosovo
                </p> */}
                <div className="contact_info">
                  {data?.contact_info?.map((el, index) => {
                    return <p>{el?.content}</p>;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

Contact.propTypes = {
  config: PropTypes.object.isRequired,
};
