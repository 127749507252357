import React from 'react';
import ReactPlayer from 'react-player';
import './PromoVideo.scss';
// import PromoV from '../../images/promovideo.mp4';

const PromoVideo = ({ url }) => {
  return (
    <div className="promo-video">
      {/* <p>djhshg</p> */}
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          // url={PromoV}
          url={url}
          width="100%"
          height="100%"
          controls={true}
          // playing={true}
        />
      </div>
    </div>
  );
};

export default PromoVideo;
