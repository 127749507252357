import React, { useState, useEffect } from 'react';
import { SidebarData } from './SidebarData';
import { useDetectOutsideClick } from './useDetectOutsideClick';
import { useRef } from 'react';
import { FaBars } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';

// CSS
import './Menu.scss';

const Menu = () => {
  const dropdownRef = useRef(null);
  const [sidebar, setSidebar] = useDetectOutsideClick(dropdownRef, false);
  const showSidebar = () => setSidebar(!sidebar);

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get('https://test.foodsafe.community/wp-json/acf/v3/pages/30/banner')
      .then((res) => {
        setData(res.data);
      });
  }, []);

  return (
    <>
      <div className="navbar__">
        <div className="navbar">
          <Link to="#" className="menu-bars menu_icon">
            <FaBars onClick={showSidebar} />
          </Link>
        </div>

        <nav
          ref={dropdownRef}
          className={sidebar ? 'nav-menu' : 'nav-menu active'}
        >
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiOutlineClose onClick={showSidebar} />
              </Link>
            </li>
            <div className="list_items">
              {SidebarData.map((item, index) => {
                console.log(item);
                return (
                  <li key={index} className={item.cName}>
                    <HashLink to={item?.path}>{item?.title}</HashLink>
                    {/* <a href={item?.path}>
                        <span>{item.title}</span>
                      </a> */}
                  </li>
                );
              })}
            </div>
          </ul>
          <div className="social_media">
            <ul>
              <a
                // href="https://www.facebook.com/foodsafe.community"
                href={data?.banner?.facebook}
                target="_blank"
                rel="noreferrer"
              >
                <li>Facebook</li>
              </a>
              <a
                // href="https://www.instagram.com/foodsafe_community"
                href={data?.banner?.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <li>Instagram</li>
              </a>
              <a
                // href="https://twitter.com/foodsafecomm"
                href={data?.banner?.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <li>Twitter</li>
              </a>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Menu;
