import React, { useState } from 'react';
import { ImageGroup, Image } from 'react-fullscreen-image';
import { PieChart } from 'react-minimal-pie-chart';
// import { Canvas } from 'react-canvas-js';
import renderHTML from 'react-render-html';
import './Statistics.scss';
import Typical from 'react-typical';
// import CanvasJSReact from './canvasjs.react';
// var CanvasJS = CanvasJSReact.CanvasJS;
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
// CSS
import CountUp from 'react-countup';

const Statistics = (props) => {
  const { data } = props;
  let imagePath = data?.image;

  console.log(props, 'statsss');

  console.log(data, 'stats');

  return (
    <>
      <div className="statistics">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Statistikat</h1>
            </div>
            <div className="col-md-12">
              <div className="stat_text">
                <p className="title">
                  <b> Mbeturinat Ushqimore</b> <br />
                  <span className="uppercase"> Problem Global</span>
                </p>
                <p>
                  Gjatë zinxhirit të furnizimit me ushqim çdo vit në botë <br />
                  <b>
                    <CountUp
                      start={900000000}
                      end={1300000000}
                      duration={4}
                      separator=" "
                      decimal=","
                      //  prefix="EUR "
                      suffix=" Tonelata"
                      className="count_up"
                    >
                      {/* 1,300,000,000 */}
                    </CountUp>
                  </b>
                  {/* <b> Tonelata</b>  */}
                  <br />
                  të ushqimeve të prodhuara përfundojnë të pakonsumuara!
                </p>
                <p>
                  Sistemi ushqimor:
                  <br />
                  <b style={{ color: '#173142' }}>
                    Fermerët {'>'} Mbledhësit {'>'} Ruajtja {'>'} Shpërndarja{' '}
                    {'>'} Shitja {'>'}
                    Konsumatorët{' '}
                  </b>
                </p>
              </div>
            </div>

            <div className="col-md-12">
              <div className="stat_4">
                <p>
                  {/* <b> 820 milion njerëz</b>
                   */}
                  <b>
                    <CountUp
                      start={500}
                      end={820}
                      duration={5}
                      separator=" "
                      decimal=","
                      //  prefix="EUR "
                      suffix=" milion njerëz "
                      className="count_up"
                    >
                      {/* <b> 820</b> */}
                    </CountUp>
                  </b>
                  nuk kanë ushqim për të ngrënë.
                </p>
              </div>
            </div>

            <div className="col-md-12">
              <div className="charts">
                <div className="row">
                  {data?.map((el, index) => {
                    return (
                      <div className="col-md-4">
                        <div className="chart_container">
                          <PieChart
                            // animation
                            // animationDuration={500}
                            // animationEasing="ease-out"
                            center={[50, 50]}
                            data={el?.percentage?.map((el, index) => {
                              return {
                                title: el?.title,
                                value: Number(el?.value),
                                color: el?.color,
                              };
                            })}
                            // abelPosition={50}
                            paddingAngle={100}
                            lengthAngle={360}
                            lineWidth={45}
                            paddingAngle={2}
                            radius={50}
                            // rounded
                            startAngle={-40}
                            viewBoxSize={[100, 100]}
                            label={(data) => data.dataEntry.title}
                            labelPosition={0}
                            labelStyle={{
                              fontSize: '12px',
                              fontColor: 'FFFFFA',
                              fontWeight: '800',
                            }}
                          />
                        </div>

                        <p className="chart_text">
                          {el?.text && renderHTML(el?.text)}
                        </p>
                      </div>
                    );
                  })}
                  {/* <div className="col-md-4">
                    <div className="chart_container">
                      <PieChart
                        animation
                        animationDuration={500}
                        animationEasing="ease-out"
                        center={[50, 50]}
                        data={[
                          { title: '91.5%', value: 91.5, color: '#173142' },
                          { title: '', value: 8.5, color: '#2aced7' },
                        ]}
                        // abelPosition={50}
                        lengthAngle={360}
                        lineWidth={45}
                        paddingAngle={0}
                        radius={50}
                        // rounded
                        startAngle={0}
                        viewBoxSize={[100, 100]}
                        label={(data) => data.dataEntry.title}
                        labelPosition={35} 
                        labelStyle={{
                          fontSize: '12px',
                          fontColor: 'FFFFFA',
                          fontWeight: '800',
                        }}
                      />
                    </div>
                    <p>
                      <b>91.5% </b>e vëllimit total të ujit të pijshëm për
                      prodhim të mallrave të destinuara për konsum shfrytëzohen
                      për prodhime agrokulturore.
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="chart_container">
                      <PieChart
                        animation
                        animationDuration={500}
                        animationEasing="ease-out"
                        center={[50, 50]}
                        data={[
                          { title: '8%', value: 8, color: '#173142' },
                          { title: '', value: 92, color: '#7dad3f' },
                        ]}
                        // abelPosition={50}
                        lengthAngle={360}
                        lineWidth={45}
                        paddingAngle={0}
                        radius={50}
                        // rounded
                        startAngle={0}
                        viewBoxSize={[100, 100]}
                        label={(data) => data.dataEntry.title}
                        labelPosition={25}
                        labelStyle={{
                          fontSize: '12px',
                          fontColor: 'FFFFFA',
                          fontWeight: '800',
                        }}
                      />
                    </div>
                    <p>
                      <b> 8% </b>të emetimeve të gazrave serrë shkaktohen nga
                      Mbeturinat Ushqimore.
                    </p>
                  </div> */}
                  {/* <div className="col-md-12">
                    <div className="stat_4">
                      <p>
                        { <b> 820 milion njerëz</b>
                         }
                        <b>
                          <CountUp
                            start={500}
                            end={820}
                            duration={5}
                            separator=" "
                            decimal=","
                            //  prefix="EUR "
                            suffix=" milion njerëz "
                            className="count_up"
                          >
                            { <b> 820</b> }
                          </CountUp>
                        </b>
                        nuk kanë ushqim për të ngrënë.
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            {/* <div className="col-md-12">
              <ImageGroup>
                <ul className="images">
                  {data?.map((el, index) => (
                    <li key={index}>
                      <Image
                        src={el?.image}
                        alt="nature"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          height: '100% ',
                          width: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </ImageGroup>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
