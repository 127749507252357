import React, { useState, useEffect } from 'react';
// import HeroSection from '../HeroSection/HeroSection';
// import PostImg from '../../images/hero_img.jpg';
import { Link } from 'react-router-dom';
import './ViewBlog.scss';
import axios from '../../axios';
import renderHTML from 'react-render-html';
import GridLoader from 'react-spinners/GridLoader';
import { css } from '@emotion/react';
import Footer from '../Footer/Footer';
import { FaClock } from 'react-icons/fa';
import Menu from '../Menu/Menu';
import Logo from '../../images/LOGO_GREEN.png';
import ReactPaginate from 'react-paginate';

const ViewBlog = (props) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #red;
    position: absolute;
    top: 50%;
    left: 50%;
  `;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [heroData, setHeroData] = useState([]);
  let [color, setColor] = useState('#0d381f');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [footerData, setFooterData] = useState([]);

  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;
  let displayUsers = [];
  const pageCount = Math.ceil(filteredUsers.length / usersPerPage);

  const changePage = ({ selected }) => {
    console.log(selected);
    setPageNumber(selected);
  };

  useEffect(() => {
    axios.get('acf/v3/pages/30').then((res) => {
      setHeroData(res.data.acf.banner);
      setFooterData(res.data.acf.footer);
      setLoading(false);
    });

    axios
      .get('wl/v1/posts')
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setFilteredUsers(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return loading === true ? (
    <GridLoader color={color} loading={loading} css={override} size={20} />
  ) : (
    <div className="view_blog">
      <div className="row">
        <div className="col-12 banner">
          {/* <HeroSection data={heroData} /> */}

          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
            <Menu />
          </div>
          {/* <h1>News Page</h1> */}
        </div>

        <div className="posts">
          <div className="row">
            {
              (displayUsers = filteredUsers
                ?.slice(pagesVisited, pagesVisited + usersPerPage)
                ?.map((el, index) => {
                  return (
                    <div className="col-md-6 all_posts">
                      <div className="post_img">
                        <img src={el?.featured_image?.large} alt={el?.title} />
                      </div>

                      <div className="post_title">
                        <h1> {el?.title} </h1>
                      </div>

                      <div className="date">
                        <FaClock />
                        {'  '}
                        {el?.date}
                      </div>

                      <p>{el?.content && renderHTML(el?.content)}</p>

                      <Link
                        to={{
                          pathname: `/singlepost/${el?.slug}`,
                          state: {
                            data: el,
                          },
                        }}
                      >
                        Lexo më shume ...
                      </Link>
                    </div>
                  );
                }))
            }
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={'paginationBttns'}
            previousLinkClassName={'previousBttn'}
            nextLinkClassName={'nextBttn'}
            disabledClassName={'paginationDisabled'}
            activeClassName={'paginationActive'}
          />
        </div>
      </div>
      {/* <Footer /> */}
      <Footer data={footerData} />
    </div>
  );
};

export default ViewBlog;
