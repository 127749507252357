import React from 'react';
import renderHTML from 'react-render-html';
import './OurStory.scss';
import AppStore from '../../images/app-store-badge.png';
import GooglePlay from '../../images/google-play-badge.png';
import { Link } from 'react-router-dom';

const OurStory = (props) => {
  const { left, right } = props;

  return (
    <>
      <div className="our_story" id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6 left">
              <div className="left_content">
                <div className="story_text">
                  <p className="story--title">{left?.title}</p>
                  {left?.content && renderHTML(left?.content)}
                </div>
                <div className="phone_img">
                  <img src={left?.image} alt="phone" />
                </div>
              </div>

              <div className="outer">
                <div className="inner"></div>
              </div>
            </div>

            <div className="col-md-6 left">
              <div className="right_content">
                <div className="phone__img">
                  <img src={right?.image} alt="phone" />
                </div>
                <div className="story_text">
                  {right?.content && renderHTML(right?.content)}
                  <div className="phone__imgg">
                    <img src={right?.image} alt="phone" />
                  </div>
                  <div className="btn_partners">
                    <a
                      // href="https://apps.apple.com/us/app/foodsafe-community/id1574681312"
                      href={right?.appstore}
                      className="btn_playstore"
                      target="_blank"
                    >
                      <img src={AppStore} alt="app store" />
                    </a>
                    <a
                      // href="https://play.google.com/store/apps/details?id=com.harrisia.green_foundation"
                      href={right?.googleplay}
                      className="btn_playstore"
                      target="_blank"
                    >
                      <img src={GooglePlay} alt="google play" />
                    </a>
                    {/* <a className="btn_appstore">App Store</a> */}
                    {/* <button className="btn_playstore">Play Store</button>
                    <button className="btn_appstore">App Store</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurStory;
