import React, { useState, useEffect } from 'react';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import axios from 'axios';
import './Donatoret.scss';
import { Link } from 'react-router-dom';

const Donatoret = () => {
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //   },
  // };

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get('https://test.foodsafe.community/wp-json/acf/v3/options/options')
      .then((res) => {
        setData(res.data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="donatoret" id="donatoret">
      <div className="container">
        <div className="row">
          <div className="col-md-12 title">
            <h1>Donatorët</h1>
          </div>
          <div className="col-md-12  img_donatoret">
            {/* <Carousel responsive={responsive}> */}
            {data?.acf?.donatoret?.map((el, index) => {
              return (
                <Link to={el?.link}>
                  <img src={el?.image} />
                </Link>
              );
            })}
            {/* </Carousel> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donatoret;
