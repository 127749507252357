import Axios from 'axios';
require('dotenv').config();

const axiosInstance = Axios.create({
  // baseURL: "http://localhost:5000/",
  // baseURL: 'http://hotspotcacttus.com/green-blog/wp-json/',
  baseURL: 'https://test.foodsafe.community/wp-json/',
  // baseURL: process.env.REACT_APP_URL,
  // baseURL: "https://testapi.starpokes.com/",
});

export default axiosInstance;
