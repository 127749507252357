export const SidebarData = [
  //   {
  //     title: "Home",
  //     path: "/",
  //     cName: "nav-text",
  //   },
  {
    title: 'Rreth Nesh',
    path: '/#about',
    cName: 'nav-text',
  },
  {
    title: 'Regjistro Biznesin',
    path: '/regjistrobiznesin',
    cName: 'nav-text',
  },
  // {
  //   title: 'Partnerët tanë',
  //   path: '/#partneret',
  //   cName: 'nav-text',
  // },
  {
    title: 'Ngjarjet e fundit',
    path: '/viewblog',
    cName: 'nav-text',
  },
  {
    title: 'Pyetjet më të shpeshta',
    path: '/#faq',
    cName: 'nav-text',
  },
  {
    title: 'Donatoret',
    path: '/#donatoret',
    cName: 'nav-text',
  },
  {
    title: 'Kontakti',
    path: '/#contact',
    cName: 'nav-text',
  },
];
