import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Components
import Menu from '../Menu/Menu';
import Form from './Form';
import Footer from '../../components/Footer/Footer';

// images
import Logo from '../../images/LOGO_WHITE.png';

// CSS
import './RegjistroBiznesin.scss';
import axios from '../../axios';

const RegjistroBiznesin = () => {
  const [footerData, setFooterData] = useState([]);

  useEffect(() => {
    axios.get('acf/v3/pages/30').then((res) => {
      setFooterData(res.data.acf.footer);
    });
  }, []);
  return (
    <>
      <div className="regjistro_biznesin">
        <div className="fjeshil-container ">
          <div className="row w-100">
            <div className="col-12 logo">
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
              <Menu />
            </div>
            <div className="col-md-12">
              <div className="title pl-4">
                <h1>Regjistro Biznesin</h1>
              </div>
              <div className="regjistro_form">
                <Form />
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      <Footer data={footerData} />
    </>
  );
};

export default RegjistroBiznesin;
