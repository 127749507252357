import React, { useState, useEffect } from 'react';
import ScrollTop from '../ScrollTop/ScrollTop';
import { Link } from 'react-router-dom';
import './Footer.scss';

const Footer = (props) => {
  let { data } = props;
  console.log('footeriiiiiii', data);
  return (
    <div className="footer">
      <footer>
        <ScrollTop />

        <div className="footer_links">
          <ul>
            {/* <a href={data?.facebook} rel="noopener noreferrer" target="_blank">
              <li>Facebook</li>
            </a> */}
            <a
              href={data?.[0]?.facebook}
              rel="noopener noreferrer"
              target="_blank"
            >
              <li>Facebook</li>
            </a>
            <a
              href={data?.[0]?.instagram}
              rel="noopener noreferrer"
              target="_blank"
            >
              <li>Instagram</li>
            </a>

            <a
              href={data?.[0]?.twitter}
              rel="noopener noreferrer"
              target="_blank"
            >
              <li>Twitter</li>
            </a>
          </ul>
        </div>

        <div className="footer_desc">
          {/* <p>
            hello@foodsafe.com | +383 (0) 38 72 61 61 | Ismail Dumoshi Nr 120, Kosovë Prishtinë */}
          {/* {data?.email} | {data?.tel} | {data?.address} */}
          {/* </p> */}

          {data?.map((el, index) => {
            return (
              <p key={index}>
                {el?.email} | {el?.tel} | {el?.address}
              </p>
            );
          })}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
