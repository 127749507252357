import React, { useState } from 'react';
import { FaChevronCircleUp } from 'react-icons/fa';
import './ScrollTop.scss';

const ScrollTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
           in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);
  return (
    <>
      <div className="scroll_btn">
        <FaChevronCircleUp
          onClick={scrollToTop}
          style={{ display: visible ? 'inline' : 'none' }}
        />
      </div>
    </>
  );
};

export default ScrollTop;
