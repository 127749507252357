import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/LOGO_GREEN.png';
import Footer from '../Footer/Footer';
import './SinglePage.scss';
import renderHTML from 'react-render-html';
import Menu from '../Menu/Menu';
import axios from '../../axios';
import { css } from '@emotion/react';
import GridLoader from 'react-spinners/GridLoader';

const SinglePage = (props) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #red;
    position: absolute;
    top: 50%;
    left: 50%;
  `;

  const [loading, setLoading] = useState(true);
  let [postData, setPostData] = useState([]);
  let [color, setColor] = useState('teal');
  const [footerData, setFooterData] = useState([]);
  console.log(window.location.href, 'url');
  let geturl = window.location.href;
  let url = geturl.split('/');

  console.log(url);

  useEffect(() => {
    axios.get('acf/v3/pages/30').then((res) => {
      setFooterData(res.data.acf.footer);
      setLoading(false);
    });

    axios
      .get(`wl/v1/posts/${url[4]}`)
      .then((res) => {
        setPostData(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  console.log(postData);

  return loading === true ? (
    <GridLoader color={color} loading={loading} css={override} size={20} />
  ) : (
    <>
      <div className="single_post">
        <div className="row">
          <div className="col-12 logo">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
            <Menu />
            {/* <HeroSection /> */}
          </div>

          <div className="col-md-10 offset-md-1 "></div>
          <div className="col-md-10  offset-md-1 description">
            <div className="post_title">
              <h1> {postData?.title} </h1>
            </div>
            <div className="date">
              <p>{postData?.date}</p>
            </div>
            <div className="post_img">
              <img
                src={postData?.featured_image?.large}
                alt={postData?.title}
              />
            </div>
            <p>{postData?.content && renderHTML(postData?.content)}</p>
          </div>
        </div>
      </div>

      <Footer data={footerData} />
    </>
  );
};

export default SinglePage;
