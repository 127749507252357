import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Blog.scss';
import axios from '../../axios';
import renderHTML from 'react-render-html';
import { FaClock } from 'react-icons/fa';

const Blog = (props) => {
  const [data, setData] = useState([]);
  // console.log(data);

  useEffect(() => {
    axios
      .get('wl/v1/posts')
      .then((res) => {
        setData(res.data.slice(0, 3));
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="blog" id="blogu">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="blog_title">
                <h1>Ngjarjet e fundit</h1>
              </div>
            </div>

            {data?.map((el, index) => {
              // console.log(el?.featured_image?.large);
              return (
                <div className="col-md-4" key={index}>
                  <div className=" blog_posts">
                    <img
                      src={
                        el?.featured_image?.large == false
                          ? 'https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png'
                          : el?.featured_image?.large
                      }
                      alt={el?.title}
                    />
                    <div className="description">
                      <h2 className="title">{el?.title}</h2>
                      <p className="date">
                        <FaClock /> {el?.date}
                      </p>
                      <p>{el?.content && renderHTML(el?.content)}</p>
                      <div className="read_more">
                        <Link
                          to={{
                            pathname: `/singlepost/${el?.slug}`,
                            state: {
                              data: el,
                            },
                          }}
                        >
                          Lexo më shumë ...
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="col-md-12 view_blog">
              <Link to="/viewblog">Lexo më shumë</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
