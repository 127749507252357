import React from 'react';
import './Faq.scss';

const Faq = (props) => {
  let { data } = props;

  console.log(data);

  let first_three = data?.faq_items.slice(0, 7);
  let last_three = data?.faq_items.slice(-9);

  return (
    <div className="faq" id="faq">
      <div className="container">
        <div className="row ">
          <div className="faq_content">
            <div className="col-md-12 ">
              <div className="faq_text">
                <h1>{data?.title}</h1>
              </div>
            </div>
            <div className="col-md-12 faq_list">
              <ol>
                <div className="left_list">
                  {first_three?.map((el, index) => {
                    return (
                      <li key={index}>
                        <h5>{el?.faq_title}</h5>
                        <p>
                          {' '}
                          <p>{el?.faq_description}</p>
                        </p>
                      </li>
                    );
                  })}
                </div>
                <div className="right_list">
                  {last_three?.map((el, index) => {
                    return (
                      <li key={index}>
                        <h5>{el?.faq_title}</h5>

                        <p>
                          {' '}
                          <p>{el?.faq_description}</p>
                        </p>
                      </li>
                    );
                  })}
                </div>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
