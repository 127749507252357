import React from 'react';
// import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import SinglePage from './components/SinglePage/SinglePage';
import ViewBlog from './components/ViewBlog/ViewBlog';
import RegjistroBiznesin from './components/RegjistroBiznesin/RegjistroBiznesin';
import AktivizimiLlogarise from './components/AktivizimiLlogarise/AktivizimiLlogarise';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TermsAndConditions from './components/TermsAndConditions/AktivizimiLlogarise/TermsAndConditions';
// import Footer from './components/Footer/Footer';

function App() {
  return (
    <>
      <div className="container-fluid p-0">
        <ToastContainer />
        <Router basename="/">
          <Route exact path="/" component={Home} data={'Something'} />
          <Route path="/singlepost/:slug" component={SinglePage} exact />
          <Route path="/viewblog" component={ViewBlog} exact />
          <Route
            path="/regjistrobiznesin"
            component={RegjistroBiznesin}
            exact
          />
          <Route
            path="/aktivizimillogarise"
            component={AktivizimiLlogarise}
            exact
          />
          <Route
            path="/termatdhekushtet"
            component={TermsAndConditions}
            exact
          />
          <Switch></Switch>
        </Router>
      </div>
    </>
  );
}

export default App;
