import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Menu from '../../Menu/Menu';
import './TermsAndConditions.scss';
import Logo from '../../../images/LOGO_GREEN.png';
import axios from 'axios';
import renderHTML from 'react-render-html';

const TermsAndConditions = () => {
  const [data, setData] = useState([]);
  // console.log(data);

  useEffect(() => {
    axios
      .get('https://testapi.foodsafe.community/api/v1/general/terms')
      .then((res) => {
        setData(res.data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="terms">
        <div className="row">
          <div className="col-12 logo">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
            <Menu />
            {/* <HeroSection /> */}
          </div>
          <div className=" col-12 tekst_konfirmimi">
            <h3>KUSHTET DHE RREGULLAT</h3>
            <h5>Platforma online e komunitetit Food Safe</h5>
            <ol className="my_list">
              {/* <li className="my_list2">
                
              </li> */}
              <li className="terms_list">
                <b>Lënda</b>
                <p>
                  1.1 - Platforma online e Komunitetit Food Safe dhe Shërbimi i
                  Aplikacioneve për Pajisjet Mobile zotërohen nga Fondacioni
                  Jeshil (numri i regjistrimit 5200309-1; numri fiskal
                  601145619), organizata mjedisore jofitimprurëse, e ndodhur në
                  rrugën ‘Ismail Dumoshi’ nr.120, Prishtinë 10000, Kosovë.
                </p>
                <p>
                  1.2 - Kushtet dhe rregullat e mëposhtme të përgjithshme
                  rregullojnë përdorimin e portalit në internet dhe të shërbimit
                  të aplikacioneve për pajisjet mobile ("Platforma") për
                  rregullimin e rezervimit të ushqimit me rrezik skadimi dhe
                  hedhjeje në mbeturina ("Produktet") nga restorantet, hotelet,
                  supermarketet, furrat e bukës, fermat, shpërndarësit e
                  ushqimeve dhe shitësit me pakicë (kolektivisht "Dyqanet") të
                  ofruara nga Platforma e Komunitetit Food Safe (më tutje "Food
                  Safe") për përdoruesit e fundmë ("Konsumatorët", "ju" ose
                  "juaj”).
                </p>
                <p>
                  1.3 - Këto Kushte dhe Rregulla vlejnë për përdorimin e
                  Platformës dhe të Aplikacionit Food Safe ("Aplikacioni"), që
                  mund të shkarkohet nga dyqanet e aplikacioneve (përmbledhtazi
                  "Shërbimet"). Qasja dhe përdorimi prej jush i shërbimeve u
                  nënshtrohet Kushteve dhe Rregullave të mëposhtme, si dhe të
                  gjitha ligjeve në fuqi. Duke hyrë ose duke përdorë ndonjë
                  pjesë të platformës, ju pranoni, pa kufizim ose kualifikim,
                  këto Kushte dhe Rregulla. Nëse nuk pajtoheni me të gjitha
                  Kushtet dhe Rregullat e përcaktuara më poshtë, nuk mund ta
                  përdorni asnjë pjesë të platformës.
                </p>
                <p>
                  1.4 - Misioni i Food Safe është që ta shtojë vetëdijesimin për
                  mbeturinat ushqimore dhe t'i zvogëlojë ato në Republikën e
                  Kosovës, duke i bërë Produktet të disponueshme për rezervime
                  nga konsumatorët. Ne ofrojmë mundësinë që Konsumatori t'ia
                  komunikojë rezervimet e veta për Produkte Dyqaneve të
                  paraqitura në Platformën tonë.
                </p>
                <p>
                  1.5 - Këto Kushte vlejnë për çdo rezervim të Produkteve që
                  kryhet përmes Platformës sonë. Platforma jonë ofrohet për
                  përdorim personal, jokomercial dhe vetëm për qëllime
                  informative. Çdo përdorim tjetër i Shërbimeve kërkon miratimin
                  paraprak me shkrim nga Food Safe.
                </p>
                <p>
                  1.6 - Cookie-t dhe politika e privatësisë e Food Safe, të
                  disponueshme në çdo kohë në Platformë, do të formojnë pjesë të
                  integruar të këtyre Kushteve dhe Rregullave dhe do të
                  aprovohen automatikisht me pranimin e këtyre Kushteve.
                  Politika e Food Safe për cookie dhe privatësi përfshihen këtu
                  me referencë. Çdo korrespondencë me Food Safe mund të na
                  dorëzohet përmes faqes së kontaktit në Platformë, Aplikacionit
                  ose emailit në: info@fondacionijeshil.org.
                </p>
                <p>
                  1.7 - Food Safe përditëson vazhdimisht, ndryshon dhe zhvillon
                  shërbimet në internet sipas vlerësimeve të veta. Prandaj
                  përdoruesit mund t'i përdorin vetëm shërbimet në formën
                  përkatëse të ofruar në një kohë të caktuar. Food Safe ruan të
                  drejtën që të ndërprejë shërbimet e funksionimit ose të heqë
                  një shërbim të caktuar, pa dhënë shpjegime
                </p>
                <p>
                  1.8 - Përdoruesit janë përgjegjës që të sigurohen se softueri
                  dhe hardueri i tyre janë të përshtatshëm dhe të përditësuar.
                </p>
                <p>
                  1.9 - Përdoruesit kanë mundësinë të përdorin shërbimet e
                  partnerëve kontraktues të Food Safe për shërbime të ndryshme
                  online që do të sigurojnë ushqime në bazë donacionesh dhe
                  produkte ushqimore përmes aplikacionit smartphone online
                  (Android dhe iOS). Në këto raste, do të lidhet një kontratë e
                  veçantë midis përdoruesit dhe partnerit përkatës kontraktual
                  të Food Safe. Përdoruesit do të njoftohen për këtë në mënyrë
                  të përshtatshme para lidhjes së kontratës.
                </p>
                <p>
                  1.10 - Dispozitat e tjera ose kushtet e përgjithshme të
                  përdoruesit, që devijojnë nga kushtet dhe rregullat e
                  përgjithshme të Food Safe, do të vlejnë vetëm nëse Food Safe
                  jep pëlqimin paraprak me shkrim për vlefshmërinë e tyre.
                </p>
              </li>
              <li className="terms_list">
                <b> Lidhja e kontratës</b>
                <p>
                  2.1 - Për t'i përdorë shërbimet online të ofruara nga Food
                  Safe, përdoruesit duhet të regjistrohen dhe ta instalojnë
                  aplikacionin në fjalë.
                </p>
                <p>
                  2.2 - Personat fizikë dhe juridikë kanë të drejtë regjistrimi
                  dhe lejeje për të qenë përdorues të autorizuar.
                </p>
                <p>2.3 - Fëmijët nën 13 vjeç nuk lejohen të regjistrohen.</p>
                <p>
                  2.4 - Përdoruesit nuk lejohen të shkarkojnë aplikacionin
                  përmes Play Store dhe App Store nëse janë: • Të ndodhur në një
                  shtet që i nënshtrohet një embargoje nga BE dhe/ose qeveria e
                  ShBA-së ose në listën e BE-së dhe/ose të qeverisë amerikane me
                  palë të ndaluara ose të kufizuara. • Në një shtet që BE
                  dhe/ose qeveria amerikane e klasifikon si mbështetëse të
                  terrorizmit.{' '}
                </p>
                <p>
                  2.5 - Përdoruesit duhet të japin emrin, mbiemrin, gjininë,
                  moshën, numrin e telefonit (opsionale) dhe përgjithësisht një
                  adresë emaili të regjistruar të përdoruesit, në mënyrë që të
                  regjistrohen në shërbimet në internet; Përdoruesit duhet të
                  sigurohen se informacioni që ia japin Food Safe gjatë
                  regjistrimit është i mirëfilltë dhe i plotë.
                </p>
                <p>
                  2.6 - Konsumatorët duhet të regjistrohen për llogaritë e tyre
                  përmes aplikacionit Food Safe për telefona të mençur, ndërsa
                  Furnitorët duhet të regjistrohen përmes aplikacionit në
                  internet.
                </p>
                <p>
                  2.7 - Regjistrimi i kryer përmes palëve të treta, veçanërisht
                  atyre që regjistrojnë persona individualë komercialë të
                  ofruesve të ndryshëm shërbimesh (shërbime regjistrimi dhe/ose
                  shërbime të hyrjes), nuk lejohet.
                </p>
                <p>
                  2.8 - Pas regjistrimit të suksesshëm, përdoruesi krijon një
                  llogari ("llogaria e përdoruesit") që të mund ta menaxhojë në
                  mënyrë të pavarur aplikacionin Food Safe përmes (Android dhe
                  iOS). Llogaria e përdoruesit nuk mund të transferohet pa
                  miratimin e qartë të Food Safe.{' '}
                </p>
              </li>
              <li className="terms_list">
                <b>Detyrimet e Përgjithshme të Përdoruesit </b>
                <p>
                  3.1 - Përdoruesi
                  <p>
                    Përdoruesit dakordohen që menjëherë ta njoftojnë Food Safe
                    për çdo ndryshim të ardhshëm në të dhënat e futura pas
                    regjistrimit, sidomos çdo ndryshim në email dhe në adresën
                    fizike e numrin e telefonit. Përdoruesit janë të detyruar
                    t'i konfirmojnë Food Safe saktësinë e të dhënave të tyre
                    sipas kërkesës.
                  </p>
                </p>
                <p>
                  3.2 - Të dhënat e kyçjes, identifikimi, fjalëkalimet
                  <ul className="sub_list">
                    <li>
                      3.2.1 - Përdoruesve u kërkohet të ruajnë
                      konfidencialitetin e plotë në lidhje me të gjitha të
                      dhënat e kyçjes, identifikimin dhe fjalëkalimet.
                    </li>
                    <li>
                      3.2.2 - Termat "të dhëna të kyçjes", "identifikim" dhe
                      "fjalëkalime" përfshijnë të gjitha sekuencat e shkronjave
                      dhe/ose karaktereve dhe/ose numrave të përdorur për ta
                      vërtetuar përdoruesin dhe për ta parandaluar përdorimin e
                      paautorizuar nga palët e treta. Fjalëkalimi nuk duhet të
                      jetë identik me emrin e përdoruesit dhe duhet të përbëhet
                      nga minimumi 8 shkronja dhe/ose numra.
                    </li>
                    <li>
                      3.2.3 - Përdoruesve u kërkohet të mbrojnë të gjitha të
                      dhënat e kyçjes, identifikimin dhe fjalëkalimet nga qasja
                      e palëve të treta.
                    </li>
                    <li>
                      3.2.4 - Në rast se një përdorues ka arsye të besojë se
                      palët e treta kanë marrë ose mund të kenë marrë pa
                      autorizim të dhëna të kyçjes, identifikimin ose
                      fjalëkalimet, do ta informojë Food Safe menjëherë dhe do
                      të ndryshojë të dhënat e veta ose t'ia ndryshojë Food
                      Safe. Në këtë rast ose nëse Food Safe ka prova konkrete të
                      keqpërdorimit të të dhënave; Food Safe ka të drejtë të
                      bllokojë përkohësisht hyrjen në llogarinë e përdoruesit.
                      Nëse ka prova të keqpërdorimit të të dhënave, Food Safe do
                      ta informojë përdoruesin.
                    </li>
                    <li>
                      3.2.5 - Përdoruesit në asnjë rrethanë nuk kanë të drejtë
                      të përdorin të dhënat e kyçjes të një përdoruesi tjetër.
                    </li>
                  </ul>
                </p>
                <p>
                  3.3 - Përdorimi i faqeve të internetit të Food Safe dhe
                  përmbajtja e tyre
                  <ul>
                    <li>
                      3.3.1 - Faqja e internetit e Food Safe përfshin përmbajtje
                      të ndryshme që mbrohet nga markat tregtare, të drejtat e
                      autorit ose mjete të tjera për të mirën e Food Safe ose të
                      palëve të treta. Përveç nëse lejohet shprehimisht brenda
                      këtyre kushteve dhe rregullave të përgjithshme,
                      përdoruesit nuk mund të modifikojnë, të kopjojnë, të
                      shpërndajnë, të riprodhojnë publikisht, të përdorin për
                      qëllime reklamimi, ose të përdorin përtej qëllimeve të
                      dakorduara asnjërën nga faqet e internetit të Food Safe,
                      ose përmbajtjen ose pjesë të saj. Lejohen vetëm kopjet
                      teknike të synuara për qëllime shfletimi dhe kopjet e
                      përhershme të synuara vetëm për përdorim privat.
                      Informacioni mbi të drejtat e autorit dhe emrat e markave
                      nuk mund të ndryshohet, fshihet ose hiqet.
                    </li>
                    <li>
                      3.3.2 - Termi "përmbajtje" përfshin të gjitha të dhënat,
                      imazhet, tekstet, grafikat, muzikat, tingujt, sekuencat e
                      zërit, videot, programet dhe kodet softuerike, si dhe
                      informacione të tjera të ofruara nga Food Safe. Termi
                      "përmbajtje" gjithashtu përfshin, në veçanti, të gjitha
                      shërbimet e disponueshme për t'u shkarkuar.
                    </li>
                    <li>
                      3.3.3 - Përdoruesit detyrohen të abstenojnë nga çdo masë
                      që mund të rrezikojë ose të ndërpresë funksionimin e
                      mirëfilltë të faqeve të internetit të Food Safe; të
                      çfarëdo shërbimi individual dhe ose ofertash të atyshme.
                      Përdoruesit po ashtu duhet të përmbahen nga çdo masë që
                      mund të lejojë qasje të paautorizuar në të dhënat.
                      Përmbajtja mund të thirret vetëm në atë mënyrë që nuk
                      ndikon në përdorimin prej përdoruesve të tjerë të faqeve
                      të internetit dhe të përmbajtjes së Food Safe. Transferimi
                      i të dhënave ose softuerit që mund të ndikojë në harduerin
                      ose softuerin e marrësve nuk lejohet.
                    </li>
                    <li>
                      3.3.4 - Çdo përdorim i faqeve të internetit të Food Safe
                      për qëllime tregtare, veçmas për qëllime reklamimi, kërkon
                      miratimin paraprak me shkrim nga Food Safe.
                    </li>
                    <li>
                      3.3.5 - Lidhja me faqen e internetit të Food Safe lejohet
                      me kusht që ta bëni në mënyrë të drejtë dhe të ligjshme,
                      që nuk na e dëmton reputacionin ose nuk e shfrytëzon atë.
                      Nuk duhet të bëni lidhje në mënyrë të tillë që të
                      sugjerojë ndonjë formë asociacioni, miratimi ose pranimi
                      nga ana jonë, kur nuk ka të tillë. Faqja jonë nuk duhet të
                      vendoset në asnjë faqe tjetër. Ne rezervojmë të drejtën
                      për ta tërhequr, pa njoftim, lejen e lidhjes. Nëse doni të
                      përfitoni nga përmbajtja në faqen tonë përveç asaj të
                      përcaktuar më lart, na kontaktoni në:
                      info@fondacionijeshil.org.
                    </li>
                    <li>
                      3.3.6 - Përdoruesit nuk kanë të drejtë të publikojnë
                      përmbajtje në faqet e internetit të Food Safe, ose në
                      shërbime të tjera.
                    </li>
                    <li>
                      3.3.7 - Faqet e Food Safe nuk mund të përdoren përmes
                      ndonjë shërbimi anonimizimi që fsheh adresën IP të vërtetë
                      të përdoruesit.
                    </li>
                  </ul>
                </p>
                <p>
                  3.4 - Përdorimi i softuerit prej klientit
                  <p>
                    Në rastin e ofertave që kërkojnë instalimin paraprak të një
                    softueri prej klientit, Food Safe i jep përdoruesit të
                    drejtë të plotë, joekskluzive, të patransferueshme,
                    personale dhe të kufizuar në kohë në lidhje me marrëveshjen
                    e përdoruesit për të instaluar softuerin e klientit dhe për
                    ta përdorë atë për qëllimet e dakorduara, në përputhje me
                    këto kushte dhe rregulla të përgjithshme.
                  </p>
                </p>
              </li>

              <li className="terms_list">
                <b>Koncepti Food Safe</b>
                <p>
                  4.1 - Produktet vihen në dispozicion për rezervim në
                  Platformë, ndërsa Konsumatorët mund të rezervojnë Produkte
                  duke vënë një Urdhër Rezervimi (siç përcaktohet më poshtë) në
                  Platformë.
                </p>
                <p>
                  4.2 - Konsumatori rezervon Produktet kur Food Safe e konfirmon
                  përmes një Konfirmimi të Rezervimit (siç përcaktohet më
                  poshtë). Sapo Konsumatori të rezervojë një Produkt dhe Dyqani
                  të pranojë Porosinë e Rezervimit, Konsumatori duhet të jetë i
                  pranishëm në Dyqan në kohën e Pranim-Dorëzimit (siç
                  përcaktohet më poshtë). Të dy palët kanë të drejtë anulimi,
                  siç përcaktohet më poshtë.
                </p>
                <p>
                  4.3 - Food Safe vetëm lehtëson rezervimet e produkteve nga një
                  Dyqan te një Konsumator. Food Safe nuk ka asnjë përgjegjësi në
                  lidhje me Produktet ose përmbushjen e ndonjë rezervimi a
                  marrëveshjeje midis Dyqanit dhe Konsumatorit.
                </p>
              </li>
              <li className="terms_list">
                <b>Pranimi</b>
                <p>
                  5.1 - Kur vendos një porosi në Platformë, Konsumatori paraqet
                  dhe garanton se si Konsumator është
                  <ul>
                    <li>
                      Ligjërisht i aftë për të hyrë në marrëveshje detyruese,
                    </li>
                    <li>Konsumator që blen për qëllime personale,</li>
                    <li>Të paktën 18 vjeçar, dhe</li>
                    <li>
                      Nëse Konsumatori është nën 18 vjeç, Konsumatori po përdor
                      Shërbimet nën mbikëqyrjen e një të rrituri që është të
                      paktën 18 vjeç. Në raste të tilla, përdoruesi i rritur
                      konsiderohet përdorues i Shërbimeve. Nëse jeni nën 13
                      vjeç, nuk mund të përdorni Shërbimet në asnjë rrethanë e
                      për asnjë arsye.
                    </li>
                  </ul>
                </p>
                <p>
                  5.2 - Duke pranuar këto Kushte dhe Rregulla, Konsumatori
                  pajtohet të pranojë të gjitha informacionet përkatëse në
                  Anglisht. Për më tej, Konsumatori pajtohet që Food Safe të
                  ruajë të gjitha marrëveshjet midis Konsumatorit dhe Food Safe,
                  dhe çdo informacion tjetër të nevojshëm për kryerjen e
                  Shërbimeve, në përputhje me politikën e privatësisë së Food
                  Safe.
                </p>
                <p>
                  5.3 - Për më tej, duke pranuar këto Kushte dhe Rregulla,
                  Konsumatori pranon të marrë emaile dhe mesazhe me tekst,
                  lidhur me çdo Porosi Rezervimi të vendosur nga Konsumatori.
                  Kjo kërkohet nga Food Safe për t'u siguruar se Konsumatori
                  merr të gjitha njoftimet thelbësore lidhur me Porosinë e
                  Rezervimit.
                </p>
                <p>
                  5.4 - Food Safe rezervon të drejtën për t'i rishikuar dhe
                  ndryshuar Kushtet kohë pas kohe. Porositë e Rezervimit prej
                  Konsumatorit do t'i nënshtrohen Kushteve të zbatueshme në
                  kohën kur Konsumatori bën Porosinë e Rezervimit.
                </p>
                <p>
                  5.5 - Food Safe, sipas vlerësimit vetjak, mund të refuzojë të
                  ofrojë Shërbime për çdo person ose subjekt, si dhe të
                  ndryshojë kriteret e pranueshmërisë për Shërbimet në çdo kohë.
                  Food Safe rezervon të drejtën për t'i mbyllur llogaritë e
                  Konsumatorëve të pezulluar ose të hequr më parë nga Shërbimet.
                </p>
              </li>

              <li className="terms_list">
                <b>Regjistrimi</b>
                <p>
                  6.1 - Për të bërë një Porosi Rezervimi (të përcaktuar më
                  poshtë) përmes Platformës me njërin nga Dyqanet, duhet të
                  regjistroheni për një llogari në Platformë ("Llogaria"), duke
                  dhënë adresën e emailit dhe fjalëkalimin.
                </p>
                <p>
                  6.2 - Duhet të ofroni informacion të saktë dhe të plotë dhe ta
                  mbani të azhurnuar informacionin e llogarisë suaj.
                </p>
                <p>
                  6.3 - Ju nuk do të: (i) zgjidhni a përdorni si emër përdoruesi
                  emrin e një personi tjetër me qëllimin për t'u paraqitur si ai
                  person; (ii) përdorni si emër përdoruesi ndonjë emër që i
                  nënshtrohet të drejtave të një personi tjetër përveç jush pa
                  autorizimin përkatës; ose (iii) përdorni si emër përdoruesi
                  ndonjë emër që është fyes, vulgar ose i pahijshëm.
                </p>
                <p>
                  6.4 - Ju personalisht jeni përgjegjës për aktivitetin që
                  zhvillohet në llogarinë tuaj dhe për mbajtjen të sigurt të
                  fjalëkalimit të llogarisë.
                </p>
                <p>
                  {' '}
                  6.5 - Asnjëherë nuk mund të përdorni pa leje Llogarinë ose
                  informacionin e regjistrimit të ndonjë personi tjetër për
                  Shërbime.
                </p>
                <p>
                  6.6 - Duhet të na njoftoni menjëherë për çdo ndryshim në të
                  drejtën tuaj për t'i përdorë Shërbimet, për shkeljen e
                  sigurisë ose përdorimin e paautorizuar të Llogarisë suaj. Ju
                  kurrë nuk duhet të publikoni, shpërndani ose postoni
                  informacione për kyçjen në llogarinë tuaj. Shihni Politikën
                  tonë të Privatësisë për më shumë informacion se si i përdorim
                  dhe i mbrojmë të dhënat tuaja personale.{' '}
                </p>
              </li>
              <li className="terms_list">
                <b>
                  Informacionet e Kontaktit dhe Shërbimi i Konsumatorit në Food
                  Safe
                </b>
                <p>
                  Komuniteti Food Safe <br />
                  Ismail Dumoshi 120, 10000 Prishtinë, Kosovë <br />
                  Shërbimi ynë i konsumatorit mund të kontaktohet përmes faqes
                  së kontaktit në Aplikacion ose drejtpërdrejt me email:
                  info@fondacionijeshil.org.
                </p>
              </li>
              <li className="terms_list">
                <b>Produktet dhe Informacioni i Produkteve</b>
                <p>
                  8.1 - Food Safe ekskluzivisht lehtëson Rezervimin e Produktit
                  midis Konsumatorit dhe Dyqanit dhe Food Safe nuk ka asnjë
                  përgjegjësi sa u përket Produkteve ose përmbushjes së ndonjë
                  kontrate midis Dyqanit dhe Konsumatorit.
                </p>
                <p>
                  8.2 - Food Safe në asnjë mënyrë nuk prodhon, shet, blen, ruan,
                  përgatit, nxjerr, përpunon, shënon, paketon, sjell a trajton
                  asnjë Produkt. Food Safe nuk ka asnjë përgjegjësi për
                  përmbushjen e asnjë Porosie Rezervimi në lidhje me Produktet,
                  duke përfshirë prodhimin, shitjen, blerjen, magazinimin,
                  përgatitjen, nxjerrjen, përpunimin, shënimin, dorëzimin,
                  cilësinë, përbërjen, alergjenët ose trajtimin e Produkteve,
                  dhe pajtueshmëria me ligjet dhe rregulloret e zbatueshme, duke
                  përfshirë atë në lidhje me sa më sipër, përveç në rastet kur
                  Food Safe përcaktohet qartë si prodhues ose shitës i Produktit
                  në Platformë.
                </p>
                <p>
                  8.4 - Nëse Konsumatori ka dyshime mbi paralajmërimet e
                  alergjisë, përmbajtjen e një Produkti ose ndonjë informacion
                  tjetër të menysë, Konsumatori do të konfirmojë me Dyqanin
                  drejtpërdrejt in 6 situ para se të përmbyllet transaksioni.
                  Informacioni i kontaktit i Dyqanit do të shfaqet në Platformë.
                  Konsumatori gjithmonë mund ta anulojë Porosinë e Rezervimit,
                  pa asnjë kufizim para afatit të fundit të pranim-dorëzimit të
                  produktit. Food Safe nuk është përgjegjës për asnjë përbërës,
                  alergji, informacion kalorish ose tjetër ushqyes të ofruar nga
                  Dyqanet ose që ndryshe gjendet në Platformë. Është përgjegjësi
                  e Konsumatorit dhe e Dyqaneve të konfirmojnë nëse ka ndonjë
                  alergjen në Produkte.
                </p>
                <p>
                  8.5 - Me rastin e pranim-dorëzimit (siç përcaktohet më
                  poshtë), Dyqani do t'i ofrojë Konsumatorit informacion mbi
                  listën e përbërësve, alergjenëve dhe informacione të tjera
                  lidhur me etiketat që kanë të bëjnë me Produktin. Çdo Produkt
                  i pranuar nga një Dyqan pas një Porosie Rezervimi do të
                  konsumohet menjëherë pas Pranim-Dorëzimit dhe/ose siç
                  udhëzohet nga etiketa e Produktit ose Dyqani. Food Safe nuk do
                  të marrë asnjë përgjegjësi për reagimet e pafavorshme të
                  Konsumatorëve nga Produktet për çfarëdo arsye, përfshirë edhe
                  rastet kur konsumohen në kundërshtim me etiketimin ose
                  informacionin e përcaktuar në këto kushte, ose të dhëna
                  drejtpërdrejt nga Dyqani.
                </p>
                <p>
                  8.6 - Food Safe nuk është përgjegjëse a llogaridhënëse për
                  asnjë dështim ose vonesë në kryerjen e ndonjë detyrimi në
                  lidhje me Produktet, përfshirë ato në lidhje me prodhimin,
                  shitjen, blerjen, magazinimin, përgatitjen, nxjerrjen,
                  përpunimin, shënimin, dorëzimin, cilësinë, përbërësit,
                  alergjenët ose trajtimin e produkteve.
                </p>
              </li>
              <li className="terms_list">
                <b>Rezervimi i produkteve </b>
                <p>
                  9.1 - Lista e Dyqaneve aktive mund të gjendet në Platformë.
                  Nëse Konsumatori ka lejuar Platformën të përdorë shërbimet e
                  vendndodhjes, Platforma do ta gjurmojë vendndodhjen e
                  Konsumatorit dhe lista do t'i shfaqë dyqanet afër
                  Konsumatorit. Pasi Konsumatori të ketë zgjedhur Dyqanin dhe
                  Produktin, do t'i jepet mundësia të paraqesë Porosinë e vet të
                  Rezervimit duke klikuar "rezervo", "vendos rezervimin tim" ose
                  ndonjë buton të ngjashëm.
                </p>
                <p>
                  9.2 - Lista përcaktohet vetëm duke marrë parasysh kriteret
                  gjeografike. Konsumatori ka mundësinë të filtrojë rezultatet
                  në konsideratë të disponueshmërisë së Produkteve për t'i
                  rezervuar, të orës së Pranim-Dorëzimit, të natyrës së
                  Produkteve të përfshira në "Çantën Magjike", ose siç
                  përcaktohet ndryshe nga Food Safe.
                </p>
                <p>9.3 - Dyqanet e referuara janë ato aktivet në Platformë.</p>
                <p>
                  9.4 - Furnizimi i Produkteve në Platformë është vetëm një
                  ftesë për Konsumatorin për të vendosur një Rezervim.
                </p>
                <p>
                  9.5 - Një Porosi Rezervimi e bërë nga Konsumatori përmes
                  Platformës ("Rezervim" ose "Porosi Rezervimi") konsiderohet
                  ofertë nga Konsumatori bërë Dyqanit për një Produkt.
                </p>
                <p>
                  9.6 - Porosia e Rezervimit do të pranohet nga Dyqani me rastin
                  e Pranim-Dorëzimit dhe Rezervimi do të quhet përfundimtar dhe
                  i detyrueshëm për Dyqanin dhe Konsumatorin pas
                  Pranim-Dorëzimit të Produktit (siç përcaktohet më poshtë),
                  megjithatë, duke iu nënshtruar kufizimeve në anulim.
                </p>
              </li>
              <li className="terms_list">
                <b>Konfirmimi i rezervimit </b>
                <p>
                  10.1 - Pas marrjes së Porosisë së Rezervimit, Food Safe do të
                  fillojë përpunimin e saj duke dërguar Rezervimin te Dyqani
                  përkatës. Food Safe do të njoftojë Konsumatorin se Porosia e
                  Rezervimit është marrë ("Konfirmimi i Rezervimit") dhe po
                  përpunohet. Ju lutemi vini re se çdo faqe konfirmimi që
                  Konsumatori mund ta vizitojë në Platformë thjesht tregon se
                  Rezervimi është marrë dhe po procesohet, e nuk do të thotë se
                  Rezervimi është pranuar nga Dyqani.
                </p>
                <p>
                  10.2 - Konsumatori duhet ta ruajë Konfirmimin e Rezervimit.
                  Konfirmimi i Rezervimit do të përmbajë informacion në lidhje
                  me rezervimin e vendosur nga Konsumatori.
                </p>
                <p>
                  10.3 - Ju lutemi vini re se Konfirmimi i Rezervimit nuk do të
                  thotë se rezervimi është pranuar, por është thjesht konfirmim
                  i marrjes së Porosisë së Rezervimit
                </p>
              </li>
              <li className="terms_list">
                <b> E drejta e Anulimit</b>
                <p>
                  11.1 - Dyqani mund të anulojë Porosinë e Rezervimit vetëm në
                  rast gabimi në futjen e të dhënave për disponueshmërinë e
                  stoqeve. Në këtë rast, Konsumatori do të marrë një njoftim
                  anulimi nga Food Safe ose Dyqani përmes emailit dhe njoftimit
                  me aplikacion, me kusht që informacioni i kontaktit i
                  regjistruar në Food Safe është i saktë dhe që Konsumatori
                  është pajtuar të marrë njoftime të tilla.
                </p>
                <p>
                  11.2 - Meqë Produktet janë mallra që mund të prishen dhe për
                  shkak se koncepti i Food Safe është të shmangë hedhjen e
                  ushqimit, Konsumatori mund të anulojë Porosinë e Rezervimit
                  vetëm deri në 30 minuta para kohës së caktuar të
                  Pranim-Dorëzimit nga Dyqani.
                </p>
              </li>
              <li className="terms_list">
                <b>Pranim-Dorëzimi</b>
                <p>
                  12.1 - Produktet e rezervuara në Platformë duhet të merren nga
                  Konsumatori në adresën e deklaruar të Pranim-Dorëzimit
                  ("Pranim-Dorëzimi"). Koha e paracaktuar e Pranim-Dorëzimit
                  është në një periudhë kohe prej 3 orësh por mund të jetë më e
                  gjatë sipas vlerësimit të Dyqanit, përkatësisht ndërmjet 3 dhe
                  12 orë. Përshkrimi i dyqanit dhe detajet mbi kohën dhe vendin
                  ku mund të merren produktet do të shfaqen në Platformë dhe
                  figurojnë edhe në Konfirmimin e Rezervimit, e përveç kësaj,
                  Përdoruesi do të marrë një njoftim. Keni parasysh se nëse
                  Konsumatori arrin shumë vonë, Dyqani mund të jetë i mbyllur
                  dhe/ose Produkti mund të jetë i padisponueshëm.
                </p>
                <p>
                  12.2 - Gjatë Pranim-dorëzimit, Konsumatori do t'ia tregojë
                  Konfirmimin e vet të Rezervimit në aplikacionin Food Safe
                  punonjësit të Dyqanit, e pas kësaj do t'ia dorëzojnë Produktet
                  e rezervuara. Është përgjegjësia e Konsumatorit të sigurohet
                  që Konfirmimi i Rezervimit në aplikacion të shfaqet në
                  Pranim-Dorëzim. Konsumatorit i kërkohet të sigurohet që
                  Produktet e porositura dhe numri i Produkteve të dorëzuara
                  përkojnë me Porosinë e Rezervimit të Konsumatorit.
                </p>
              </li>
              <li className="terms_list">
                <b> Pasojat e shkeljes së detyrës</b>
                <p>
                  13.1 - Food Safe nuk mban përgjegjësi për dëmet që rezultojnë
                  nga shkelja e detyrës prej përdoruesit.
                </p>
                <p>
                  13.2 - Pavarësisht nga çdo e drejtë ligjore ose kontraktuale
                  shtesë, është në diskrecionin e arsyeshëm të Food Safe të
                  ndërmarrë veprimet e mëposhtme kundër çdo përdoruesi fajtor
                  për shkeljen e ligjit, të drejtave të palëve të treta, të
                  këtyre kushteve dhe rregullave të përgjithshme, ose të ndonjë
                  rregulloreje përkatëse shtesë:
                  <ol type="a">
                    <li>Hiq përmbajtjen,</li>
                    <li>Lëshoji një paralajmërim përdoruesit, </li>
                    <li>
                      Blloko përkohësisht ose përgjithmonë një përdorues nga
                      përmbajtja e faqeve të internetit të Food Safe,
                    </li>
                    <li>Pezullo një përdorues,</li>
                    <li>
                      Lësho një ndalesë të përkohshme ose të përhershme virtuale
                      nga faqja e internetit, ose
                    </li>
                    <li>Menjëherë mbyll kontratën.</li>
                  </ol>
                </p>
                <p>
                  13.3 - Nëse përdoruesit janë bllokuar ose përjashtuar, mund të
                  mos kyçen përsëri në shërbim të ofruar nga Food Safe pa
                  miratimin paraprak të Food Safe.
                </p>
              </li>
              <li className="terms_list">
                <b>Kufizimi i Përgjegjësisë</b>
                <p>
                  14.1 - Përdoruesit mbajnë përgjegjësi personale për çdo
                  shkelje të të drejtave të një pale të tretë. Përdoruesit
                  pajtohen të rimbursojnë Food Safe për të gjitha dëmet që
                  rezultojnë nga mosrespektimi i fajshëm i detyrimeve të këtyre
                  Kushteve dhe Rregullave. Përdoruesit e lirojnë Food Safe nga
                  të gjitha pretendimet e pranueshme që përdoruesit e tjerë ose
                  palët e treta mund të paraqesin kundër Food Safe për shkak të
                  shkeljes së të drejtave të tyre nga përmbajtja e postuar prej
                  përdoruesit ose për shkak të shkeljes së detyrimeve të tjera.
                  Përdoruesit do të marrin përsipër shpenzimet e mbrojtjes
                  ligjore të Food Safe, duke përfshirë të gjitha tarifat
                  gjyqësore dhe ligjore. Ky kusht nuk vlen nëse përdoruesi nuk
                  është përgjegjës për shkeljen.{' '}
                </p>
                <p>
                  14.2 - Përgjegjësia e Food Safe, për çfarëdo arsye ligjore,
                  qoftë për shkak të sjelljes së keqe kontraktuale ose shkeljes
                  së detyrës, përcaktohet përfundimisht në përputhje me
                  rregullat e mëposhtme:{' '}
                  <ul>
                    <li>
                      14.2.1 - Nëse Food Safe ofron falas shërbimin përkatës që
                      shkakton përgjegjësi, Food Safe përgjigjet vetëm për
                      keqdashje dhe neglizhencë të rëndë.
                    </li>
                    <li>
                      14.2.2 - Në rastin e shërbimeve që nuk vijnë falas,
                      përgjegjësia e Food Safe është e kufizuar në rastet e
                      keqdashjes dhe të neglizhencës së rëndë, por e pakufizuar
                      në rastet e dëmtimit personal. Sidoqoftë, në raste
                      neglizhence të lehtë, Food Safe do të jetë përgjegjës për
                      shkelje të detyrimeve thelbësore kontraktuale siç janë
                      vonesa ose mosdisponueshmëria, vetëm në rastet kur
                      aplikacioni nuk ka arritur të sinkronizojë të dhënat. Në
                      rast të shkeljes së një detyrimi thelbësor kontraktual,
                      përgjegjësia është e kufizuar në dëmet tipike kontraktuale
                      që Food Safe duhej t'i llogariste me përfundimin e
                      kontratës për shkak të rrethanave që mbizotëronin në këtë
                      kohë. "Detyrimet thelbësore kontraktuale", siç u tha më
                      lart, janë ato në të cilat mund të mbështetet përdoruesi
                      dhe që sigurojnë se kontrata funksionon në përputhje me
                      rregullat e saj dhe arrin qëllimin kontraktual.
                    </li>
                    <li>
                      14.2.3 - Food Safe nuk merr asnjë përgjegjësi për
                      ndërprerjet brenda rrjetit që nuk janë faji i Food Safe.
                    </li>
                    <li>
                      14.2.4 - Food Safe është përgjegjës për humbjen e të
                      dhënave në përputhje me paragrafët e mësipërm vetëm nëse
                      humbja e tillë nuk mund të shmangej nga masat e duhura
                      rezervë të ndërmarra nga përdoruesi.
                    </li>
                    <li>
                      14.2.5 - Kufizimet e lartpërmendura të përgjegjësisë nuk
                      vlejnë në rastet e garancive eksplicite të bëra nga Food
                      Safe, në rastet e keqdashjes dhe për dëmet për shkak të
                      humbjes së jetës, gjymtyrëve ose shëndetit. Përgjegjësia
                      do të mbetet e paprekur sipas Direktivës së Përgjegjësisë
                      së Produktit.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="terms_list">
                <b>
                  Kohëzgjatja e kontratës; Fshirja e llogarive të përdoruesit
                </b>
                <p>
                  15.1 - Në qoftë se nuk thuhet shprehimisht ndryshe, kontrata
                  për përdorimin e faqes së internetit të Food Safe dhe të
                  shërbimeve të tjera zgjat për një periudhë të pacaktuar.
                  Kontrata fillon me akreditimin ose aktivizimin e një llogarie
                  nga Food Safe.
                </p>
                <p>
                  15.2 - Secila palë mund ta mbyllë kontratën në çdo kohë me
                  efekt të menjëhershëm, me kusht që të mos jetë dakorduar një
                  periudhë e kontratës së përkohshme.
                </p>
                <p>
                  15.3 - Secila palë ka të drejtë të mbyllë kontratën për një
                  arsye të mirë pa dhënë një periudhë paralajmërimi. "Shkak të
                  mirë" përbëjnë rastet kur:
                  <ol type="a">
                    <li>palët e treta përdorin llogarinë e përdoruesit,</li>
                    <li>
                      një përdorues ka shitur, blerë ose tregtuar një llogari
                      përdoruesi, ose
                    </li>
                    <li>
                      përdoruesi me fajësi shkel ligjet që shërbejnë për të
                      mbrojtur Food Safe,
                    </li>
                    <li>
                      përdoruesi shkel qëllimisht këto kushte dhe rregulla të
                      përgjithshme.
                    </li>
                  </ol>
                </p>
                <p>
                  15.4 - Mbyllja duhet të bëhet gjithmonë me shkrim. Mbylljet e
                  dërguara me email konsiderohen se janë bërë me shkrim.
                </p>
                <p>
                  15.5 - Në rastin e pasivitetit të zgjatur, Food Safe ka të
                  drejtë të fshijë llogarinë e përdoruesit joaktiv pas të paktën
                  24 muajve të pasivitetit. Për më shumë, Food Safe ka të drejtë
                  të fshijë një llogari përdoruesi në fund të kontratës sipas
                  vlerësimit vetjak.
                </p>
              </li>
              <li className="terms_list">
                <b>Zgjidhja e Mosmarrëveshjeve Online</b>
                <p>
                  16.1 - Republika e Kosovës ofron forume për zgjidhjen
                  alternative të mosmarrëveshjeve ndërmjet Konsumatorëve dhe
                  tregtarëve.
                </p>
                <p>
                  16.2 - Sidoqoftë, Food Safe nuk ka për qëllim dhe nuk është e
                  detyruar të marrë pjesë në procedurë të zgjidhjes së
                  mosmarrëveshjeve. Nëse kontaktoni Food Safe, ai zakonisht do
                  t'ju kontaktojë direkt.
                </p>
              </li>
              <li className="terms_list">
                <b>Baza Ligjore</b>
                <p>
                  17.1 - Do të vlejnë ligjet e Republikës së Kosovës, ndërsa
                  Konventa e OKB-së për Kontratat për Shitjen Ndërkombëtare të
                  Mallrave (CISG) do të përjashtohet. Dispozitat e detyrueshme
                  të shtetit në të cilin banon përdoruesi do të mbeten të
                  paprekura nga ky vendim ligjor.
                </p>
              </li>
              <li className="terms_list">
                <b>
                  Ndryshimet në këto kushte dhe rregulla të përgjithshme; Të
                  ndryshme{' '}
                </b>
                <p>
                  18.1 - Food Safe mban të drejtën për të modifikuar këto kushte
                  dhe rregulla (a) në rast të ndonjë ndryshimi të bërë për shkak
                  të ndryshimeve ligjore, (b) në rast të ndonjë ndryshimi të
                  bërë për shkak të praktikës gjyqësore, (c) për shkak të
                  nevojës teknike, (d) për të mirëmbajtur operacionet, (e) në
                  rast ndryshimi në kushtet e tregut, (f) për përfitimin e
                  përdoruesit. Asnjë ndryshim nuk do të bëhet nëse ndryshimi i
                  tillë do ta prishte ndjeshëm ekuilibrin kontraktual midis
                  palëve. Përdoruesit do të informohen për çdo ndryshim në
                  kushtet dhe rregullat e përgjithshme përmes platformës së Food
                  Safe (Web, Android dhe iOS). Përndryshe, Food Safe mund t'u
                  dërgojë përdoruesve të vet kushtet dhe rregullat e
                  përgjithshme të ndryshuara përmes emailit ose t'i informojë
                  ata se kushtet dhe rregullat e përgjithshme të ndryshuara mund
                  të gjenden në faqet e internetit të Food Safe. Përdoruesi ka
                  të drejtë të kundërshtojë çfarëdo ndryshimi brenda katër
                  javësh. Kushtet dhe rregullat konsiderohet të jenë pranuar
                  nëse përdoruesi mbetet i heshtur për periudhën katërjavore
                  pasi ka marrë njoftimin për kushtet dhe rregullat e
                  ndryshuara. Food Safe do të informojë specifikisht përdoruesit
                  e vet për periudhën katër-javore, të drejtën e tërheqjes dhe
                  domethënien ligjore të heshtjes.
                </p>
                <p>
                  18.2 - Përdoruesit kanë të drejtë për kompensim vetëm nëse
                  kundërpadia e tyre është vërtetuar ligjërisht ose është njohur
                  nga Food Safe dhe është e pakontestueshme ose ka rezultuar nga
                  kjo marrëdhënie juridike e ndërsjellë. Përdoruesit mund të
                  ushtrojnë të drejtën e vazhdimit vetëm me kusht që të ketë të
                  bëjë me pretendimet e dala nga kjo kontratë.
                </p>
                <p>
                  18.3 - Food Safe në përgjithësi komunikon me përdoruesin
                  përmes emailit - përveç nëse përcaktohet ndryshe në këto
                  kushte dhe rregulla. Përdoruesit duhet të sigurohen që të
                  kontrollojnë rregullisht llogarinë e emailit të dhënë gjatë
                  regjistrimit, për mesazhe nga Food Safe. Kur kontaktojnë Food
                  Safe, përdoruesit duhet të tregojnë se me cilën llogari online
                  të përdoruesit ka të bëjë mesazhi.
                </p>
                <p>
                  18.4 - Nëse ndonjë dispozitë e këtyre kushteve dhe rregullave
                  është ose bëhet e pavlefshme dhe/ose kundërshton dispozitat
                  ligjore, vlefshmëria e dispozitave të mbetura të kushteve dhe
                  rregullave mbetet e paprekur.
                </p>
              </li>
            </ol>

            {/* <p className="text_1">Termat dhe kushtet e përdorimit</p> */}
            {/* <p>{data.content && renderHTML(data.content)}</p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
