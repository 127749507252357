import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import { PostData } from './PostData';
import Modal from 'react-modal';
import logo from '../../images/LOGO_GREEN.png';
import './LatestOffers.scss';
import AppStore from '../../images/app-store-badge.png';
import GooglePlay from '../../images/google-play-badge.png';
import axios from 'axios';

const LatestOffers = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const responsive = {
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 5,
      slidesToSlide: 3, // optional, default to 1.
      // paritialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 991 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
      // paritialVisibilityGutter: 50,
    },
    mobilez: {
      breakpoint: { max: 991, min: 767 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
      // paritialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 767, min: 495 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
      // paritialVisibilityGutter: 60,
    },
    mobile2: {
      breakpoint: { max: 495, min: 425 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
      // paritialVisibilityGutter: 50,
    },
    mobile3: {
      breakpoint: { max: 425, min: 375 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
      // paritialVisibilityGutter: 20,
    },
    mobile4: {
      breakpoint: { max: 375, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
      // paritialVisibilityGutter: 110,
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get('https://api.foodsafe.community/api/v1/general/products/top20')
      .then((res) => {
        setData(res.data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  Modal.setAppElement('body');
  return (
    <>
      <div className="latest_offers container ">
        <div className="row">
          <div className="col-md-12">
            <div className="title">
              <h1>Ofertat e fundit</h1>
            </div>
            <Carousel
              partialVisible={true}
              swipeable={true}
              draggable={false}
              // showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              // autoPlay={this.props.deviceType !== "mobile" ? true : false}
              autoPlay={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={300}
              containerClass="carousel-container"
              // removeArrowOnDeviceType={['tablet', 'mobile']}
              // deviceType={this.props.deviceType}
              // dotListClass="custom-dot-list-style"
              emulateTouch={true}
              itemClass="carousel-item-padding-40-px"
            >
              {
                data?.map((items) => {
                  console.log(items.pic_url);
                  return (
                    <div className="card card_list" key={items._id}>
                      <Link
                      // to={items.link}
                      >
                        <div
                          className="card-img-top"
                          onClick={() => setModalOpen(true)}
                        >
                          <img
                            src={process.env.REACT_APP_BASEURL + items.pic_url}
                            alt={items.name}
                          />
                        </div>
                      </Link>
                      <div className="card-body">
                        <h5 className="card-title">{items.name}</h5>
                        <p>{items.restaurant}</p>

                        <div className="card_des">
                          <p className="card-text">{items.category_name}</p>
                          {/* <Link to={items.link} className="book_link"> */}

                          <span>
                            {items.price == 0 ? 'Falas' : '€ ' + items.price}
                          </span>

                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                  );
                })
                // PostData.map((items) => {
                //   return (
                //     <div className="card card_list" key={items.id}>
                //       <Link to={items.link}>
                //         <div
                //           className="card-img-top"
                //           onClick={() => setModalOpen(true)}
                //         >
                //           {/* <img src={items.img} alt={items.name} /> */}
                //         </div>
                //       </Link>
                //       <div className="card-body">
                //         <h5 className="card-title">{items.name}</h5>
                //         <p>{items.restaurant}</p>

                //         <div className="card_des">
                //           <p className="card-text">{items.category}</p>
                //           {/* <Link to={items.link} className="book_link"> */}
                //           <span>{items.price}</span>
                //           {/* </Link> */}
                //         </div>
                //       </div>
                //     </div>
                //   );
                // })
              }
            </Carousel>
            <Modal
              isOpen={modalOpen}
              onRequestClose={() => setModalOpen(false)}
              style={{
                content: {
                  // backgroundImage: "url(" + img + ")",
                  overflow: 'hidden',
                  // background: " rgb(255 255 255 / 60%)",
                  borderRadius: '10px',
                  width: '570px',
                  height: '370px',
                  justifyContent: 'center',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  padding: 0,
                  border: 'none',
                },
              }}
            >
              <div className="modal_content">
                <div className="row">
                  <div className="col-4 modal_title">
                    <img src={logo} alt="foodSafe" />
                    {/* <p> Behu pjese e FoodSafe</p> */}
                  </div>
                  <div className="col-8 download_app">
                    <p>Shkarko aplikacionin</p>

                    <a
                      href="https://apps.apple.com/us/app/foodsafe-community/id1574681312"
                      target="_blank"
                    >
                      <img src={AppStore} alt="app store" />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.harrisia.green_foundation"
                      target="_blank"
                    >
                      {' '}
                      <img src={GooglePlay} alt="google play" />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="modal_content">
                <p>Download App</p>

                <Link to="#">App Store</Link>
                <Link to="#">Play Store</Link>
              </div> */}
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestOffers;
